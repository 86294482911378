<template>
  <div class="router-wrap">
    <div class="router-content">
      <div class="router-title" ref="title">
        <p>合作共赢</p>
        <img src="@/assets/imgs/cooperation/cooperation-title.png" alt="">
      </div>
      <d3Chart v-if="activeIndV == 3" :bankList="bankList"></d3Chart>
      <div class="router-text">
        <img src="@/assets/imgs/cooperation/cooperation-text.png" alt="">
      </div>
    </div>

  </div>
</template>

<script>
import d3Chart from "@/components/charts/d3.vue"
import { baseURL } from "@/api/config.js";
import { selectPartnerList } from "@/api/common.js";

export default {
  name: 'Cooperation',
  props:{
    activeIndV:[Number],
  },
  data(){
    return {
      bankList:[],
    }
  },
  watch: {
    activeIndV:function(val){
      //console.log(this.activeIndV)
      if(val== 3){
        this.$refs.title.className = "router-title translate"
      }else{
        this.$refs.title.className = "router-title"
      }
    },
  },
  components: {
    d3Chart
  },
  created(){
    this.getselectPartnerList();
  },
  methods:{
    getselectPartnerList() {
      selectPartnerList({
        lang: sessionStorage.getItem("langType"),
        showIndex: 1, //不传展示所有，传1表示显示在首页
        // organizationType: this.activeTab,
      }).then((res) => {
        let data = [];
        res.data.map((v, i) => {
          if(i <= 10){
            data.push({
              img: baseURL + v.logo,
              name: v.organizationName,
            });
          }
        });
        this.bankList = data;
      });
    },
  }
}
</script>
<style scoped lang="scss">
.router-wrap{
  height: 100%;
  background: url(../assets/imgs/common/index-bg1.jpg) center center;
  overflow: hidden;
  .router-content{
    width: 1200px;
    margin: 0 auto;
    padding-top: 180px;
    color: #fff;
    position: relative;
    .router-title{
      line-height: 1;
      font-size: 17px;
      transform: translateX(-500px);
      &.translate{
        transition: all 0.3s ease-in-out 0.3s;
        transform: translateX(0px);
      }
      &>p{
        font-size: 40px;
        margin-bottom: 26px;
      }
    }
    .router-text{
      margin-top: 482px;
      text-align: right;
    }
    .d3-chart{
      position: absolute;
      left: calc(50% - 550px);
      top: 160px;

    }
  }
}
</style>
