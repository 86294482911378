<template>
  <div class="d3-chart" @click="toDetails()">
    <ul class="bank-list">
      <li v-for="(val,ind) in bankList" :key="ind" class="" :ref="'item'" :style="getPosition(positionList[ind].row,positionList[ind].col)">
        <p>
          <img :src="val.img" alt="">
        </p>
        <span>{{val.name}}</span>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'd3-chart',
  data() {
    return {
      positionList:[
        {col:1,row:2},
        {col:1,row:3},
        {col:1,row:5},
        {col:2,row:5},
        {col:3,row:2},
        {col:3,row:4},
        {col:3,row:6},
        {col:3,row:8},
        {col:4,row:2},
        {col:4,row:6},
        {col:4,row:7},
        {col:5,row:3},
      ],
      // position:[  //
      //   [175,94],
      //   [123,182],
      // ]
    };
  },
  // watch:{
  //   bankList: function(val){
  //
  //   }
  // },
  props:{
    bankList:{
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  mounted() {
      // console.log(this.$refs.item)
      this.$refs.item.map((val)=>{
        setTimeout(()=>{
          val.className = "scale"
        },Math.random() * 700 + 100)
      })
  },
  methods: {
    getPosition(row,col){
      //获取单位的坐标，奇数行起始位175,94，偶数行起始位123,182，单位left相差104，top相差92；
      let left = (col % 2 == 1 ? 175 : 123)  + (row - 1) * 104 - (row * 0.4) + 'px'
      let top =   94 + (col - 1) * 88 + (col * 0.1) + 'px'

      return {left:left,top:top}

    },
    toDetails(){
      console.log(1212)
      this.$router.push({path: "/Partner"})
    }
  },
};
</script>
<style lang="scss" scoped>
.d3-chart{
  width: 1100px;
  height: 619px;
  margin: 0 auto;
  background: url("../../assets/imgs/cooperation/beehive-bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  cursor: pointer;

  .bank-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    line-height: 1;
    height: 100%;
    li{
      text-align: center;
      font-size: 16px;
      width: 105px;
      height: 120px;
      background: url("../../assets/imgs/cooperation/beehive-item-bg.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 0;
      top: 0;
      transition: all 0.5s;
      transform: scale(0,0);
      &.scale{
        transform: scale(1,1);
      }
      &>p{
        padding-top: 25px;
        line-height: 1;
        height: 70px;
        img{
          max-width: 35px;
        }
      }
      //&:nth-child(10){
      //  &>p{
      //    img{
      //      width: 23px;
      //    }
      //  }
      //}
    }
  }

}
</style>